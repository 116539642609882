// 微网刊统一跳转地址
export function WeChatAllJumpUrl(magId, taskId) {
  const origin = window.location.origin;
  let url = "";
  if (magId && taskId) {
    url =
      origin +
      "/otherPages/Microblog/html/catalogDetails.html?magId=" +
      magId +
      "&taskId=" +
      taskId;
  } else if (magId && !taskId) {
    url =
      origin + "/otherPages/Microblog/html/catalogDetails.html?magId=" + magId;
  } else if (!magId && taskId) {
    url =
      origin +
      "/otherPages/Microblog/html/catalogDetails.html?taskId=" +
      taskId;
  } else {
    return;
  }
  return url;
}
// 日期转换
export function FormatDateTime(date) {
  if (!date) {
    return "";
  }
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  var second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
}
// 时间戳转换为时间
export function TimestampToTime(timestamp,notShowHour) {
  var date = new Date(Number(timestamp));//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-';
  var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  if (notShowHour) {
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    return Y+M+D
  }else {
    var D = date.getDate() + ' ';
    var h = date.getHours() + ':';
    var m = date.getMinutes() + ':';
    var s = date.getSeconds();
    return Y+M+D+h+m+s;
  }
}
